import { useEffect, useRef } from "react";
import * as THREE from "three";
import { DotScreenShader } from "./custom-shader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

function Left() {
  const canvas = useRef(null);

  useEffect(() => {
    let camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );
    let renderer = new THREE.WebGLRenderer();
    let scene = new THREE.Scene();

    let height = canvas.current.offsetHeight;
    let width = canvas.current.offsetWidth;

    let time = 0;
    let isPlaying = true;

    let composer, cubeCamera, material, renderTarget;

    const effect = new ShaderPass(DotScreenShader);

    camera.position.set(0, 0, 1.25);

    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.physicallyCorrectLights = true;
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.setSize(width, height);

    canvas.current.appendChild(renderer.domElement);

    addObjects();
    initPost();
    resize();
    render();
    setupResize();

    function addObjects() {
      renderTarget = new THREE.WebGLCubeRenderTarget(256, {
        encoding: THREE.sRGBEncoding,
        format: THREE.RGBAFormat,
        generateMipmaps: true,
        minFilter: THREE.LinearMipMapLinearFilter,
      });
      cubeCamera = new THREE.CubeCamera(0.1, 10, renderTarget);
      material = new THREE.ShaderMaterial({
        extensions: {
          derivatives: "#extension GL_OES_standard_derivatives : enable",
        },
        fragmentShader: `
          uniform float time;
          varying vec3 vPosition;
          float PI = 3.141592653589793238;
          vec4 mod289(vec4 x) {
            return x - floor(x * (1.0 / 289.0)) * 289.0;
          }
          vec4 perm(vec4 x) {
            return mod289(((x * 34.0) + 1.0) * x);
          }
          float noise(vec3 p) {
            vec3 a = floor(p);
            vec3 d = p - a;
            d = d * d * (3.0 - 2.0 * d);
            vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
            vec4 k1 = perm(b.xyxy);
            vec4 k2 = perm(k1.xyxy + b.zzww);
            vec4 c = k2 + a.zzzz;
            vec4 k3 = perm(c);
            vec4 k4 = perm(c + 1.0);
            vec4 o1 = fract(k3 * (1.0 / 41.0));
            vec4 o2 = fract(k4 * (1.0 / 41.0));
            vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
            vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);
            return o4.y * d.y + o4.x * (1.0 - d.y);
          }
          float lines(vec2 uv, float offset) {
            return smoothstep(0., 0.5 + offset * 0.5, 0.5 * abs((sin(uv.x * 35.) + offset * 2.)));
          }
          mat2 rotate2D(float angle) {
            return mat2(cos(angle), -sin(angle), sin(angle), cos(angle));
          }
          void main() {
            vec3 baseFirst = vec3(0. / 255., 255. / 255., 0. / 255.);
            vec3 accent = vec3(0., 0., 0.);
            vec3 baseSecond = vec3(0. / 255., 255. / 255., 0. / 255.);
            float n = noise(vPosition + time);
            vec2 baseUV = rotate2D(n) * vPosition.xy * 0.1;
            float basePattern = lines(baseUV, 0.5);
            float secondPattern = lines(baseUV, 0.15);
            vec3 baseColor = mix(baseSecond, baseFirst, basePattern);
            vec3 secondBaseColor = mix(baseColor, accent, secondPattern);
            gl_FragColor = vec4(vec3(secondBaseColor), 1.);
          }
        `,
        side: THREE.DoubleSide,
        uniforms: {
          time: { value: 0 },
          resolution: { value: new THREE.Vector4() },
        },
        vertexShader: `
          varying vec2 vUv;
          varying vec3 vPosition;
          float PI = 3.141592653589793238;
          void main() {
            vUv = uv;
            vPosition = position;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
      });
      let geometry = new THREE.SphereBufferGeometry(1.5, 30, 30);
      let plane = new THREE.Mesh(geometry, material);
      scene.add(plane);
    }

    function initPost() {
      composer = new EffectComposer(renderer);
      composer.addPass(new RenderPass(scene, camera));
      effect.uniforms["scale"].value = 4;
      composer.addPass(effect);
    }

    function resize() {
      height = canvas.current.offsetHeight;
      width = canvas.current.offsetWidth;
      renderer.setSize(width, height);
      composer.setSize(width, height);
      camera.aspect = width / height;
    }

    function render() {
      if (!isPlaying) return;
      time += 0.01;
      cubeCamera.update(renderer, scene);
      material.uniforms.time.value = time;
      requestAnimationFrame(render.bind(this));
      composer.render(scene, camera);
    }

    function setupResize() {
      window.addEventListener("resize", resize.bind(canvas));
    }
  });

  return <div ref={canvas} className="Left"></div>;
}

export default Left;
