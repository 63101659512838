import "./Home.css";
import Left from "./left/Left";
import Right from "./right/Right";

function Home() {
  if (window.innerWidth > 991) {
    return (
      <div className="Home">
        <Left />
        <Right />
      </div>
    );
  } else {
    return (
      <div className="Home">
        <Right />
      </div>
    );
  }
}

export default Home;
