import "./Loader.css";

function Loader({ count }) {
  return (
    <div className="Loader">
      <div className="time">{count}%</div>
      <div className="row"></div>
    </div>
  );
}

export default Loader;
