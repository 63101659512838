import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Loader from "./Loader";
import Home from "./pages/home/Home";

function App() {
  const [count, setCount] = useState(0);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    let interval = null;
    if (count <= 109) {
      interval = setInterval(() => {
        setCount((count) => count + 1);
      }, 5);
    }
    setTimeout(() => setMounted(true), 1500);
    return () => clearInterval(interval);
  }, [count]);

  if (mounted) {
    return (
      <Routes>
        <Route path="" element={<Home />} />    
      </Routes>
    );
  } else {
    return <Loader count={count} />;
  }
}

export default App;
