import Navbar from "../../../components/navbar/Navbar";

function Right() {
  return (
    <div className="Right">
      <div className="box">
        <div className="Home__box">
          <Navbar />
          blockchain engineer / security researcher.
          <div className="Home__footer">
            v2.0.0
            <div className="Home__footer-links">
              <a
                className="mb-10"
                href="mailto:mektigboy@skiff.com"
                target="_blank"
                rel="noreferrer"
              >
                email
              </a>
              <a
                className="mb-10"
                href="https://github.com/mektigboy"
                target="_blank"
                rel="noreferrer"
              >
                github
              </a>
              <a
                href="https://twitter.com/mektigboy"
                target="_blank"
                rel="noreferrer"
              >
                twitter
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Right;
