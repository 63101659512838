import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <div className="Navbar">
      <Link to="/">mektigboy</Link>
      <a href="mailto:mektigboy@skiff.com"> request private audit</a>
    </div>
  );
}

export default Navbar;
